<!--  -->
<template>
    <div class='com-user-flowerpot'>
        <div class="flowerpot-box"></div>
        <div class="flowerpot-shadow"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-user-flowerpot{
        position: relative;
        .flowerpot-box{
            width: 91px;
            height: 154px;
            .background-img-max(url('@{assetsUrlV2_0}/user/c12.png'));
        }
        .flowerpot-shadow{
            position: absolute;
            .background-img-max(url('@{assetsUrlV2_0}/user/shadow_flower.png'));
            width: 60px;
            height: 18px;
            top: 139px;
            left: -4px;
            z-index: -1;
        }
    }
</style>