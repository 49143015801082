<!--  -->
<template>
    <div class='page-user-info-summary max-size'>
        <component-bcg url="user/c1.png"></component-bcg>
        <div class="page-ext-bcg"></div>
        <div class="page-ext-bcg-b"></div>
        <div class="summary-head">
            <div class="top-left-all"></div>
            <div class="head-item top-left-a"></div>
            <div class="head-item top-left-b"></div>
            <div class="head-item user-info flex-align-center">
                <div class="user-info-body">
                    <div class="info-item a">
                        <user-photo></user-photo>
                        <div class="user-photo-bcg"></div>
                    </div>
                    <div class="user-info-box">
                        <div class="info-item b flex-center">
                            <div class="b-i-i hide-point-txt">
                                <nickname></nickname>
                            </div>
                        </div>
                        <div class="info-item level b flex-center">
                            <div class="b-i-i hide-point-txt">
                                <user-level></user-level>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="head-item top-left-c flex-center">
                <div class="c-text"></div>
                <div class="head-content-box">
                    <div class="con-box-item a">
                        <div class="a-i a"></div>
                        <div class="a-i b"></div>
                    </div>
                    <div class="con-box-item b">
                        <div class="b-i-body max-size">
                            <div class="page-item a">
                                <div class="pi-a-i a">
                                    <div class="ai-item a cursor-pointer"
                                    @click="_routeHand.goto({
                                        name: 'mistakes',
                                        type: 'push',
                                        
                                    })"
                                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                                    >
                                        <!-- query: {backName: 'user-summary'} -->
                                        <div class="ai-a-bcg"
                                        
                                        ></div>
                                    </div>
                                    <div class="ai-item b cursor-pointer"
                                    @click="_routeHand.goto({
                                        name: 'certificate',
                                        type: 'push',
                                        
                                    })"
                                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                                    >
                                        <div class="ai-b-bcg"
                                        ></div>
                                    </div>
                                </div>
                                <!-- query: {backName: 'user-summary'} -->
                                <div class="pi-a-i b cursor-pointer"
                                @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                                @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                                @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                                @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                                @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                                @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                                @click="_routeHand.goto({
                                    name: 'history',
                                    type: 'push',
                                    
                                })"
                                >
                                    <div class="bi-bcg"
                                    
                                    ></div>
                                </div>
                            </div>
                            <div class="page-item b cursor-pointer"
                            @click="_common.userSetting"
                            @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                            @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                            @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                            @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                            @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                            @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                            >
                                <div class="page-ib-bcg"
                                
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="summary-content">
            <div class="foot-ele-img a"></div>
        </div> -->
        <div class="food-box" v-if="hasPet">
            <petFood></petFood>
        </div>
        <div class="summary-foot">
            <div class="foot-ele-img a">
                <flowerpot></flowerpot>
            </div>
            <div class="foot-ele-pop">
                <!-- <div class="pop-item a">
                    <pet></pet>
                </div>
                <div class="pop-item b" v-if="userAvatar">
                    <div class="ib-shadow"></div>
                    <avatar :url="userAvatar" @click="gotoAvatar"></avatar>
                </div> -->
                <div class="ib-shadow"></div>
                <avatarPet pageName="user" @click="gotoAvatar" :gif="true" :avatarPointer="true" avatarText="点我换装"></avatarPet>
            </div>
            <div class="foot-item a"></div>
            <div class="foot-item b"></div>
        </div>
    </div>
</template>

<script>
    // import pet from '../components/pet.vue'
    // import avatar from '../components/avatar.vue'
    import flowerpot from '../components/flowerpot.vue'
    import petFood from '../components/petFood.vue'
    import apiClass from '@/API/page/user'

    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                api: new apiClass(),
                userInfo: {}
            };
        },
        methods: {
            pageInit(){
                this.userInfoInit()
            },
            async userInfoInit(){
                let res = await this.api.userAccount()
                if(!res) return
                this.userInfo = res
            },
            gotoAvatar(){
                // https://gaotu.19x19.com/token/e7cc1742-fcfe-44d1-ad53-24b7c753db41/28497f88-3c0e-45a3-92ea-992c9960d015?callback=https://interactive.gaotu.cn/course/index.html?v=1653034619
                this._routeHand.goto({
                    name: 'avatar',
                    type: 'push',
                    // query: {backName: 'user-summary'}
                })
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            // pet,
            petFood,
            flowerpot,
            // avatar
        },
        computed: {
            userAvatar(){
                let {userInfo} = this
                return userInfo.avatarDressUrl
            },
            userPet(){
                let {userInfo: {petName, petType, petStatus, petUrl}} = this
                return {petName, petType, petStatus, petUrl}
            },
            hasPet(){
                let {userPet:{petUrl,petStatus}} = this
                return petUrl && petStatus ? true : false
            },
            topAStyle(){
                let {appStyle} = this
                return {
                    width: `calc((100vw - ${appStyle.width}) / 2 + 77px)`
                }
            },
            btmAStyle(){
                let {appStyle} = this
                return {
                    width: `calc((100vw - ${appStyle.width}) / 2 + 77px)`
                }
            },
        },
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' src="./summary.less"></style>
