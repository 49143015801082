var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-user-info-summary max-size"},[_c('component-bcg',{attrs:{"url":"user/c1.png"}}),_c('div',{staticClass:"page-ext-bcg"}),_c('div',{staticClass:"page-ext-bcg-b"}),_c('div',{staticClass:"summary-head"},[_c('div',{staticClass:"top-left-all"}),_c('div',{staticClass:"head-item top-left-a"}),_c('div',{staticClass:"head-item top-left-b"}),_c('div',{staticClass:"head-item user-info flex-align-center"},[_c('div',{staticClass:"user-info-body"},[_c('div',{staticClass:"info-item a"},[_c('user-photo'),_c('div',{staticClass:"user-photo-bcg"})],1),_c('div',{staticClass:"user-info-box"},[_c('div',{staticClass:"info-item b flex-center"},[_c('div',{staticClass:"b-i-i hide-point-txt"},[_c('nickname')],1)]),_c('div',{staticClass:"info-item level b flex-center"},[_c('div',{staticClass:"b-i-i hide-point-txt"},[_c('user-level')],1)])])])]),_c('div',{staticClass:"head-item top-left-c flex-center"},[_c('div',{staticClass:"c-text"}),_c('div',{staticClass:"head-content-box"},[_vm._m(0),_c('div',{staticClass:"con-box-item b"},[_c('div',{staticClass:"b-i-body max-size"},[_c('div',{staticClass:"page-item a"},[_c('div',{staticClass:"pi-a-i a"},[_c('div',{staticClass:"ai-item a cursor-pointer",on:{"click":function($event){return _vm._routeHand.goto({
                                    name: 'mistakes',
                                    type: 'push',
                                    
                                })},"mousedown":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchstart":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"touchend":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseup":function($event){return $event.currentTarget.classList.remove('button-scale-down')}}},[_c('div',{staticClass:"ai-a-bcg"})]),_c('div',{staticClass:"ai-item b cursor-pointer",on:{"click":function($event){return _vm._routeHand.goto({
                                    name: 'certificate',
                                    type: 'push',
                                    
                                })},"mousedown":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchstart":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"touchend":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseup":function($event){return $event.currentTarget.classList.remove('button-scale-down')}}},[_c('div',{staticClass:"ai-b-bcg"})])]),_c('div',{staticClass:"pi-a-i b cursor-pointer",on:{"mousedown":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchstart":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"touchend":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseup":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"click":function($event){return _vm._routeHand.goto({
                                name: 'history',
                                type: 'push',
                                
                            })}}},[_c('div',{staticClass:"bi-bcg"})])]),_c('div',{staticClass:"page-item b cursor-pointer",on:{"click":_vm._common.userSetting,"mousedown":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchstart":function($event){return $event.currentTarget.classList.add('button-scale-down')},"touchleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseleave":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"touchend":function($event){return $event.currentTarget.classList.remove('button-scale-down')},"mouseup":function($event){return $event.currentTarget.classList.remove('button-scale-down')}}},[_c('div',{staticClass:"page-ib-bcg"})])])])])])]),(_vm.hasPet)?_c('div',{staticClass:"food-box"},[_c('petFood')],1):_vm._e(),_c('div',{staticClass:"summary-foot"},[_c('div',{staticClass:"foot-ele-img a"},[_c('flowerpot')],1),_c('div',{staticClass:"foot-ele-pop"},[_c('div',{staticClass:"ib-shadow"}),_c('avatarPet',{attrs:{"pageName":"user","gif":true,"avatarPointer":true,"avatarText":"点我换装"},on:{"click":_vm.gotoAvatar}})],1),_c('div',{staticClass:"foot-item a"}),_c('div',{staticClass:"foot-item b"})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con-box-item a"},[_c('div',{staticClass:"a-i a"}),_c('div',{staticClass:"a-i b"})])
}]

export { render, staticRenderFns }