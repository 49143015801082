<!--  -->
<template>
    <div class='com-user-pet-food flex-center'>
        <div class="food-box-item scale-button" v-for="v in foodListCul" :key="v.id" @click="feed(v)">
            <div class="food-item a">
                <img :src="v.foodUrl" class="food-icon">
            </div>
            <div class="food-item b">
                <div class="item">
                    <div class="item-body flex-center">
                        <span class="text-mini-b v2_0-color bold">{{v.foodName}}</span>
                    </div>
                </div>
                <div class="item num">
                    <div class="item-body flex-center">
                        <span class="text-mini-b v2_0-color bold">{{v.price}}</span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import apiClass from '@/API/page/user'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                price: 25,
                foodList: [],
            };
        },
        methods: {
            async feed(val){
                let res = await this.feedConfirm(val)
                if(!res) return
                this.feedAction(val)
            },
            async feedAction(val){
                let {id: foodId} = val || {}
                let res = await this.api.feedPet({foodId})
                if(res){
                    this._userHand.setPetEatUrl(res)
                    let message = this.TEXT.pet['feed success']
                    this._common.toastOpen({
                        message
                    })
                    this.api.userAccount({noLoad: true, noError: true})
                }

            },
            async feedConfirm(val){
                let message = this.TEXT.components['pet feed confirm']
                let {price} = val || {}
                message = this._common.urlParse(message, {price})
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons: [
                        {title: '取消', label: 1, type: 'cancel'},
                        {title: '确认', label: 0, type: 'confirm'},
                    ]
                })
                res = res || {}
                return res.label == 0
            },
            pageInit(){
                this.priceInit()
            },
            async priceInit(){
                let res = await this.api.petFeedGet()
                if(!res) return
                this.foodList = res
            }
        },
        created() {
            this.pageInit()
        },
        components: {
        },
        computed: {
            foodListCul(){
                let {foodList: list} = this
                let rd = []
                for(let i in list){
                    if(list[i].deleteFlag) continue
                    rd.push(list[i])
                }
                return rd
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-user-pet-food{
        .food-box-item+ .food-box-item{
            margin-left: 10px;
        }
        .food-item.a{
            margin: 0px auto 7px;
            width: 28px;
            height: 25px;
            // .background-img-max(url('@{assetsUrlV2_0}/user/c32.png'));
            img{
                width: 100%;
                height: auto;
            }

        }
        .food-item.b{
            .item.num{
                .item-body{
                    border-radius: 0px 0px 3px 3px;
                }
            }
            .item{
                text-align: center;
                display: flex;
                justify-content: center;
                .item-body{
                    line-height: 14px;
                    text-align: center;
                    // display: inline-block;
                    height: 14px;
                    padding: 0px 5px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 3px;
                }
            }
        }
    }
</style>